var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DefaultLayout',{ref:"defaultActions",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('v-container',{staticClass:"full-width"},[_c('v-btn',{staticClass:"bold-blue white-background",attrs:{"fab":"","outlined":"","dark":"","large":""},on:{"click":function($event){return _vm.goBack()}}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1),_c('v-container',[_c('v-col',{attrs:{"sm":"8"}},[(_vm.processingPaymentMessageException)?_c('v-alert',{attrs:{"outlined":"","type":"error","prominent":"","border":"left"}},[_vm._v(_vm._s(_vm.processingPaymentMessageException))]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xs":"12","lg":"9"}},[_c('v-col',{attrs:{"cols":"12","md":"9","lg":"9","xl":"6"}},[_c('PaymentTerms',{ref:"paymentTerms",attrs:{"is-selecting-payment":true},on:{"paymentProfile":_vm.setPaymentProfile},scopedSlots:_vm._u([{key:"payment-display",fn:function({ paymentProfile }){return [_c('v-card',{staticClass:"pa-4",attrs:{"flat":true}},[_c('span',{staticClass:"roboto-bold"},[_vm._v("Payment method")]),_c('PaymentSelectCard',{attrs:{"profile":paymentProfile,"should-show-description":true,"should-hover":false,"is-flat":true},scopedSlots:_vm._u([{key:"actionBtn",fn:function(){return [_c('v-btn',{staticClass:"bold-blue v-btn__no-text-decoration",attrs:{"text":""},on:{"click":function($event){return _vm.$refs.paymentTerms.openPaymentMethodDialog(false)}}},[_vm._v("Change ")])]},proxy:true}],null,true)})],1)]}}])})],1)],1)],1),_c('v-container',[(_vm.orderlines[0])?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-card',{staticClass:"order-card",attrs:{"elevation-5":""}},[_c('v-card-title',[_c('span',{staticClass:"display-identifier"},[_vm._v("Order ")]),_c('span',{staticClass:"display-title"},[_vm._v(" "+_vm._s(_vm.orderlines[0].PO))])]),_vm._l((_vm.orderlines),function(orderline,i){return _c('div',{key:orderline.Id + i,class:{
                  'card-light-grey-background': i % 2 === 0,
                  'card-dark-grey-background': i % 2 === 1
                }},[(_vm.canSeeOrderline(orderline))?_c('OrderlineCard',{staticStyle:{"border-radius":"10px"},attrs:{"orderline":orderline,"orderlines-on-order":_vm.orderlines,"item-id":orderline.Item.Id,"should-show-extended-info":true,"should-use-in-order-actions":true,"should-disable-options":true,"background-color":i % 2 === 0
                      ? 'card-light-grey-background'
                      : 'card-dark-grey-background'}}):_vm._e(),(i < _vm.orderlines.length - 1)?_c('v-divider'):_vm._e()],1)})],2)],1)],1):_vm._e()],1)],1),_c('OrderSummary',{ref:"OrderSummary",class:{
        'order-confirmation': !_vm.isMobile()
      },attrs:{"po":_vm.po,"orderlines":_vm.orderlines,"is-confirmation-summary":true,"payment-method-profile":_vm.paymentProfile,"customer-notes":_vm.customerNote},on:{"poChanged":_vm.setPO,"paymentInitiated":_vm.resetErrorMessages,"paymentDeclined":_vm.displayErrorMessage}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
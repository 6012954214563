
import { Vue, Component } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import {
  OrderlineDetails,
  Orderline,
  OrderlinePurchaseStateTypes
} from '../../store/models/OrderlineModel';
import { IsInLocalStorage, GetFromLocalStorage } from '@/helpers/StorageHelper';
import OrderSummary from '@/components/OrderSummary.vue';
import OrderEditPo from '@/components/OrderEditPo.vue';
import PaymentAdd from '@/components/PaymentAdd.vue';
import OrderlineCard from '@/components/cardComponents/OrderlineCard.vue';
import PaymentTerms from '@/components/PaymentTerms.vue';
import { CustomerService } from '../../services/customer-service';
import { OrdersService } from '@/services/orders-service';
import { DialogHelper } from '@/helpers/DialogHelper';
import { PaymentProfile } from '@/store/models/PaymentModel';
import AddEditBillingAddress from '@/components/addressComponents/AddEditBillingAddress.vue';
import { Confirm } from '@/helpers/AuthHelper';
import PaymentSelectCard from '@/components/cardComponents/PaymentSelectCard.vue';
@Component({
  components: {
    DefaultLayout,
    OrderSummary,
    PaymentAdd,
    OrderlineCard,
    OrderEditPo,
    PaymentTerms,
    AddEditBillingAddress,
    PaymentSelectCard
  }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    OrderSummary: HTMLFormElement;
    OrderEditPo: HTMLFormElement;
    paymentTerms: HTMLFormElement;
    AddEditBillingAddress: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Getter('getCurrentOrder', { namespace: 'orderlines' })
  currentOrder: any;
  @Mutation('setCurrentOrder', { namespace: 'orderlines' })
  setCurrentOrderInStore: any;
  @Action('GetOrderPos', { namespace: 'orderlines' })
  getOrderPOsFromDatabase: any;
  @Action('ChangePO', { namespace: 'orderlines' })
  changePOInStore: any;
  @Getter('getOrderPos', { namespace: 'orderlines' })
  getOrderPos: any;
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  customerNote: string = '';
  isGettingBillingAddresses: boolean = false;
  addNewPaymentDialog: boolean = false;
  orderlines: Orderline[] = [];
  po: string = '';
  paymentProfile: any = null;
  processingPaymentMessageException: string = '';
  billingAddresses: any = [];
  shippingOptions: Array<{
    title: string;
    customerAccount: string;
    description: string;
  }> = [];
  selectedShippingOption: {
    title: string;
    customerAccount: string;
    description: string;
  } | null = null;

  /* Methods */
  isMobile() {
    return this.$refs.defaultActions ? this.$refs.defaultActions.shouldUseMobile : false;
  }
  async getOrderDetails(po: string) {
    this.isLoading = true;
    try {
      if (this.currentOrder[0]) {
        this.orderlines = this.currentOrder;
      } else if (IsInLocalStorage('currentOrder', 'PO', po, OrderlineDetails)) {
        //load from local storage
        this.orderlines = GetFromLocalStorage('currentOrder', Array<OrderlineDetails>());
        this.setCurrentOrderInStore(this.orderlines);
      } else {
        alert('There was an error loading your order.');
      }
      if (this.orderlines[0]) {
        this.$refs.OrderSummary.GeneratePricingData();
      }
    } catch (err) {
      console.log(err);
    }
    this.isLoading = false;
  }
  buildShippingOptions() {
    this.shippingOptions.push({
      title: 'Standard Shipping',
      customerAccount: '285733',
      description: 'Standard items ship ground, expedited items ship priority'
    });
    this.selectedShippingOption = this.shippingOptions[0];
  }
  openEditPoDialog() {
    this.$refs.OrderEditPo.showDialog();
  }
  canSeeOrderline(Orderline: Orderline) {
    return (
      Orderline.IsVisible &&
      Orderline.OrderlinePurchaseStateType !== OrderlinePurchaseStateTypes.Delete
    );
  }
  resetErrorMessages() {
    this.processingPaymentMessageException = '';
  }
  displayErrorMessage(exceptionMessage: string) {
    this.processingPaymentMessageException = exceptionMessage;
  }
  goBack() {
    this.$router.push({ name: 'OrderView', params: { po: this.po } });
  }
  setPaymentProfile(val: PaymentProfile) {
    this.paymentProfile = val;
  }
  async hasPastDueInvoices() {
    try {
      const { data } = await OrdersService.GetPastDueOrders();
      if (data) {
        Confirm(
          () => {
            this.goToInvoices();
          },
          'You have invoices past due',
          'You have invoices past due, would you like to pay them now?',
          "I'll pay them later",
          'Pay now'
        );
      }
    } catch (error) {
      console.log(error);
    }
  }
  goToInvoices() {
    window.open(`${process.env.VUE_APP_BASE_URL}/invoicemanagement`);
    this.$refs.OrderSummary.saveForLater(false);
  }
  async setPO(val: string) {
    (this.orderlines as Orderline[]).map((ol: Orderline) => (ol.PO = val));
    if (this.po !== val) {
      this.po = val;
    }
  }
  /* Loaders */
  /* Mounted */
  async mounted() {
    if (this.$route.params.po) {
      await this.getOrderDetails(this.$route.params.po);
      await this.hasPastDueInvoices();
    }
    this.buildShippingOptions();
    this.customerNote = this.$route.params.note;
  }
  /* Created */
  created() {
    this.po = this.$route.params.po;

    this.customerNote = this.$route.params.note;
    if (this.getOrderPos.length === 0) {
      this.getOrderPOsFromDatabase();
    }
  }
}
